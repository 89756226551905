@mixin typo-normal() {
    font-size: 1.4rem;
    line-height: 2.2rem;
}

@mixin typo-small() {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

@mixin typo-smallest() {
    font-size: 1rem;
    line-height: 1.4em;
}

@mixin typo-heading-1() {
    font-size: 4.5rem;
    line-height: 4.5rem;
}

@mixin typo-heading-2() {
    font-size: 3.6rem;
    line-height: 3.6rem;
}

@mixin typo-heading-3() {
    font-size: 3rem;
    line-height: 3.6rem;
}

@mixin typo-heading-4() {
    font-size: 2.6rem;
    line-height: 3rem;
}

@mixin typo-heading-5() {
    font-size: 1.8rem;
    line-height: 2.2rem;
}

@mixin typo-heading-6() {
    font-size: 1.6rem;
    line-height: 2rem;
}

@mixin typo-small-numbers() {
    font-size: 1.4rem;
    line-height: 0;
    font-weight: normal;
}
