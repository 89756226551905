@import '../common';

.content {
    border-bottom-left-radius: var(--dialog-border-radius);
    border-bottom-right-radius: var(--dialog-border-radius);
    background-color: var(--color-bg-card);
    position: relative;
    padding: var(--spacing-md);
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.container {
    background: var(--color-bg-card-invert-lighter);
}

.actions {
    width: 100%;
    text-align: right;
    padding: var(--spacing-default);
}

.loginCodeContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    margin-top: var(--spacing-md);

    > div {
        width: var(--spacing-xl);
    }
}

.content .input input {
    text-align: center;
    font-weight: bold;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
}

.loginInputContainer {
    width: 100%;;
}

.loginInput {
    width: 100%;

    input {
        @include typo-heading-5;
        text-align: center;
    }
}

.invalidCode {
    color: var(--color-text-error);
    padding: var(--spacing-sm);
}
